// ============================
// *** DARK ***
// ============================
$colour-dark-dark: #0d111f;
$colour-dark-dark-contrast: #fff;
$colour-dark: #1a2038;
$colour-dark-contrast: #fff;
$colour-dark-light: #283157;
$colour-dark-light-contrast: #fff;
$colour-dark-accent: #6e79a7;
$colour-dark-accent-contrast: #1a2038;

// ============================
// *** PRIMARY ***
// ============================
$colour-primary-dark: #004a8a;
$colour-primary-dark-contrast: #d5ebff;
$colour-primary: #0d8efd;
$colour-primary-contrast: #fff;
$colour-primary-light: #a3d4ff;
$colour-primary-light-contrast: $colour-primary-dark;
$colour-primary-accent: #17c9ff;
$colour-primary-accent-contrast: $colour-primary-dark;

// ============================
// *** SECONDARY ***
// ============================
$colour-secondary-dark: #1f253a;
$colour-secondary-dark-contrast: #fff;
$colour-secondary: #30395a;
$colour-secondary-contrast: #fff;
$colour-secondary-light: #4f5d93;
$colour-secondary-light-contrast: $colour-secondary-dark;
$colour-secondary-accent: #717fac;
$colour-secondary-accent-contrast: $colour-secondary-dark;

// ============================
// *** LIGHT ***
// ============================
$colour-light-dark: #a7a7a7;
$colour-light-dark-contrast: $colour-dark-dark;
$colour-light: #e9e9e9;
$colour-light-contrast: #090909;
$colour-light-light: #fff;
$colour-light-light-contrast: $colour-dark-dark;
$colour-light-accent: #ededed;
$colour-light-accent-contrast: $colour-dark-dark;

// ============================
// *** ACCENT ***
// ============================
$colour-accent-dark: #181e34;
$colour-accent-dark-contrast: #fff;
$colour-accent: #4F597E;
$colour-accent-contrast: #fff;
$colour-accent-light: #717ba0;
$colour-accent-light-contrast: $colour-accent-dark;
$colour-accent-accent: $colour-dark;
$colour-accent-accent-contrast: #fff;

// ============================
// *** MISC ***
// ============================
$colour-success: #30c394;
$colour-warning: #f7a43d;
$colour-pending: #f3d44a;
$colour-background-light: #f8f8f9;
$colour-teal: #04a1a9;

// ============================
// *** ICONS ***
// ============================
$colour-icon-phone: #30c394;