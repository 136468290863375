@import "@threeskye/core-components/dist/styles/Variables.scss";
@import "../../themes/3skye.scss";
@import "../../layout/LayoutVariables.scss";


$element-spacing: 3rem;

//THEMES
// DARK THEME
.dark-theme {
	#topbar-container {
		color: #fff;
	}
	
	.notifications {
		i {
			color: #fff;
	
			&:hover {
				color: $colour-primary;
			}
		}
	}
	
	.topbar-create-btn {
		background-color: $colour-primary;
		border: 3px solid $colour-primary;
	}
	
	.create-btn-container {
		
		&:hover {
			.create-btn {
				background-color: rgba($colour-primary, 0.4);
			}
		}
	}
	
	.dd-li-icon {
		.new-event {
			color: $secondaryColour-1;
		}
	
		.upload-doc {
			color: $secondaryColour-3;
		}
	}

	#mobile-menu-btn {
		i {
			color: $colour-primary;
		}
	}
		
	#searchbar {
		i {
			color: #fff;

			&:hover {
				color: $colour-primary;
			}
		}

		&:focus-within {
			i {
				color: $colour-primary;
			}
		}
	
		input {
			background-color: lighten($colour-background, 5%);
			background-color: #30395a;
			color: $colour-form-text;
	
			&::placeholder {
				color: $colour-text;
			}
	
			&:focus {
				color: $colour-form-focus-text;
			}
		}
	}
		
	.profile-img-wrapper {	
		border: 3px solid #1a2038;

		&.no-image {
			background-color: $colour-primary;
			color: $colour-text;
		}
	}
}

// LIGHT THEME
.light-theme {
	#topbar-container {
		// background-color: $light-colour-background !important;
		color: $light-colour-text !important;

		i {
			color: $light-colour-text;
		}

		.profile-btn {
			color: $light-colour-text;
		}

		.profile-detail-container {
			&:hover {
				background-color: $light-colour-background !important;
				color: $light-colour-text;

				.profile-img-wrapper {
					border-color: $light-colour-background !important;
				}
			}
			
			.topbar-dropdown {
				background-color: $light-colour-background !important;
			}
		}
	}
	
	.notifications {
		i {
			color: #fff;
	
			&:hover {
				color: $light-colour-primary;
			}
		}
	}
	
	.topbar-create-btn {
		background-color: $light-colour-primary;
		border: 3px solid $light-colour-primary;
	}
	
	.create-btn-container {
		
		&:hover {
			.create-btn {
				background-color: rgba($light-colour-primary, 0.4);
			}
		}
	}
	
	.dd-li-icon {
		.new-event {
			color: $secondaryColour-1;
		}
	
		.upload-doc {
			color: $secondaryColour-3;
		}
	}
		
	#searchbar {
		i {
			color: $light-colour-primary;
		}
	
		input {
			background-color: lighten($light-colour-background, 5%);
			color: $light-colour-text;
	
			&::placeholder {
				color: $light-colour-text;
			}
	
			&:focus {
				color: $light-colour-text;
			}
		}
	}
		
	.profile-img-wrapper {
		border: 3px solid $light-colour-background;
	
		&.no-image {
			background-color: $light-colour-primary;
			color: $light-colour-text;
		}
	}
}

// STYLES
#topbar-container {
	position: fixed;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 100%;
	position: relative;
	justify-content: flex-start;
	width: 100%;

	@media screen and (max-width: $breakpoint-md) {
		z-index: $layer-9;
		background-color: $colour-background;
	}
}

// RHS content container
#topbar-right-content {
	display: flex;
	align-items: center;
	margin-left: auto;
}

// Notifications
.notifications {
	position: relative;
	justify-content: center;
	display: flex;
	cursor: pointer;
	margin-right: 1rem;

	i {
		font-size: 1.5rem;
	}

	&:hover {
		.topbar-dropdown {
			display: block !important;
		}
	}

	.topbar-dropdown {
		top: 110%;
	}
}

.bookmark-dd-section {
	max-height: 275px;
	overflow: auto;
}

// Dropdowns
.dd-list-item {
	cursor: pointer;
}

.dd-li-icon {
	display: flex;
	align-items: center;

	i {
		font-size: 1.2rem;
		margin-right: 0.4rem;
	}
}

.topbar-dropdown {
	display: none;
	position: absolute;
	background-color: $colour-secondary;
	padding: 1rem 0;
	border-radius: 6px;
	z-index: 1000;
	font-size: 1rem;
	box-shadow: 0 2px 3px rgba(#000000, 0.19);

	&:before {
		content: "";
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		top: -10px;
		width: 0;
		height: 0;
		border-left: 10px solid transparent;
		border-right: 10px solid transparent;
		border-bottom: 10px solid $colour-secondary;
	}

	&.bookmark-dropdown {
		top: 40px;
		width: 300px;
	}

	&.create-dropdown {
		top: 90px;
		width: 300px;
	}

	button {
		height: 36px;
		width: 100%;
	}
}

// Bookmark Dropdown
.bookmark-dd-list {
	font-size: 1rem;
	text-align: center;
	list-style-type: none;
}

.bookmark-dd-list-nothing {
	text-align: center;
}

.bookmark-dd-section {
	max-height: 275px;
	overflow: auto;
}

.bdd-accounts {
	margin-bottom: 1.5rem;
}

.bookmark-dd-item {
	display: flex;
	align-items: center;
	padding: 0.7em 0.9rem;
	transition: ease-in 200ms;
	position: relative;
	padding-right: 45px;
	word-break: break-word;

	.sa-acc-profile {
		margin-right: 0.6rem;
		width: 30px !important;
		height: 30px !important;
		min-width: auto !important;
	}

	.no-image {
		font-size: 0.85rem;
	}

	&:last-child {
		margin-bottom: 0;
	}

	&:hover {
		background-color: lighten($colour-secondary, 20%);
	}
}

.bdd-section-header {
	padding: 0.2em 0.9rem;
	font-weight: 600;
	font-size: 0.7rem;
	opacity: 0.4;
}

.remove-bm {
	font-size: 0.9rem;
	position: absolute;
	margin-top: auto;
	margin-bottom: auto;
	right: 20px;
	top: 50%;
	transform: translateY(-50%);
	color: #fff;
	opacity: 0.2;
	padding: 0.2rem;
	transition: ease-out 200ms;

	&:hover {
		opacity: 1;
		background-color: $colour-primary;
		border-radius: 10px;
	}
}

#mobile-menu-btn {
	cursor: pointer;
	i {
		font-size: 2rem;
	}
}

.saved-accounts {
	display: block;
	cursor: pointer;
	position: relative;
	transition-duration: 200ms;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 100px;
	font-size: 1.5rem;
	margin-right: 1rem;

	::-webkit-scrollbar {
		width: 0px; /* remove scrollbar space */
		background: transparent; /* optional: just make scrollbar invisible */
	}

	&:after {
		content: "";
		width: 100%;
		height: 10px;
		position: absolute;
		bottom: -10px;
		background-color: transparent;
	}

	.bookmark {
		color: #fff;
	}

	&:hover {
		.bookmark {
			color: $colour-primary;
		}

		.bookmark-dropdown {
			display: block;
		}
	}
}

// Create Button
.topbar-create-btn {
	display: block;
	margin-right: 20px;
	border-radius: 40px;
	font-size: 0.8rem;
	cursor: pointer;
	box-sizing: content-box;
}

.create-btn-container {
	position: relative;
	min-width: 96px;
	margin-right: 1rem;

	button.button-with-icon {
		i {
			margin-right: 5px;
		}
	}

	
	// This is to cover the gap between menu and button
	&:before {
		content: "";
		height: 5px;
		width: 100%;
		background-color: transparent;
		position: absolute;
		top: 100%;
		left: 0;
	}

	&:hover {
		.create-btn {
			box-shadow: 0 3px 6px rgba(#000000, 0.15);
		}

		#create-btn-dropdown {
			opacity: 1 !important;
			// height: auto !important;
		}
	}

	button {
		height: 36px;
		width: auto;
	}

	@media screen and (max-width: $breakpoint-md) {
		button {
			display: none;
		}
	}

	@media screen and (min-width: $breakpoint-md) {
		&:hover {
			#create-btn-dropdown {
				height: auto !important;
			}
		}
	}
}

#create-btn-dropdown {
	min-width: 120px;
	left: 0;
	min-width: 150px;

	// Adds the border to the last entry in the dropdown.
	ul {
		li {
			&:last-child {
				border-top: 1.5px solid rgba(#fff, 0.15);
			}
		}
	}

	h5 {
		display: none;
	}

	@media screen and (max-width: $breakpoint-md) {
		h5 {
			display: block;
			font-weight: 600;
			margin-bottom: 16px;
			margin-top: 8px;
		}
		opacity: 1 !important;
		height: 100% !important;
		background-color: #1a2038;
		z-index: 1;
		width: 100vw;
		left: 0;
		position: fixed;
		top: 70px;
		border-radius: 0;
    	box-shadow: none;
    	padding: 0 16px;

		ul {	
			li {
				font-size: 16px;
				padding: 1rem 1rem;
				background: #30395A;
    			border-radius: 4px;
    			margin-bottom: 10px;

				&:last-of-type {
					border: none;
				}
			}
		}

	}
}

.create-btn {
	display: block;
	border-radius: 4px;
	font-size: 1rem;
	font-weight: 600;
}

// AUM/FUM Container
.total-aum-container {
	font-size: 1.46rem;
	font-weight: 600;
	display: none;
	
	p {
		font-size: 10px;
		opacity: 0.4;
	}
}

// Searchbar
#searchbar {
	margin-top: 0;
	margin-left: $element-spacing;
	position: relative;
	display: none;
	width: 296px;
	
	input {
		width: 100%;
		border: none;
		font-size: 0.85rem;
		outline: none;
		transition-duration: 300ms;
		border-radius: 4px;
		padding: 0.5rem;
		margin: 0;
		padding-left: 2rem;

		&::placeholder {
			color: #fff;
			opacity: 0.3;
		}

		&:focus {
			background-color: $colour-form-focus;
			color: $colour-form-focus-text;

			&::placeholder {
				color: $colour-form-focus-text;
				opacity: 0.3;
			}
		}
	}
	
	i {
		position: absolute;
		left: 0.5rem;
		top: 50%;
		transform: translateY(-50%);
		font-size: 1rem;
		cursor: pointer;
	}
}

// Profile Button/Dropdown
.profile-dropdown-container {
	display: none;
}

.profile-img-wrapper {
	width: 44px;
	height: 44px;
	background-size: cover;
	background-position: center;
	overflow: hidden;
	border-radius: 100px;
	box-sizing: content-box;
	transition-duration: 300ms;

	&.no-image {
		font-size: 0.85rem;
		letter-spacing: 1px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.profile-detail-container {
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	position: relative;
	border-radius: 100px;
	transition-duration: 100ms;
	padding: 3px;
	pointer-events: none;
	
	.menu-spacer {
		width: 100%;
		height: 5px;
		position: absolute;
		top: 100%;
	}
	
	&:hover {
		background-color: $colour-secondary;
		color: $colour-text;
		box-shadow: 2px 0 3px rgba(#000000, 0.19);
	
		.dropdown-menu-wrapper {
			opacity: 1;
			height: auto;
			
		}
	
		.profile-img-wrapper {
			box-sizing: content-box;
			border-color: $colour-secondary;
		}
	}
}

.tb-divider {
	height: 40px;
	width: 1px;
	background-color: #30395a;
	margin-left: 32px;
	margin-right: 18px;
	display: none;
}

.profile-dropdown-container {
	position: relative;
}

.profile-name {
	font-size: 0.85rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-weight: 500;
	margin-bottom: 0.2rem;
	min-width: 88px;
	max-width: 200px;
	word-break: break-word;
}

.profile-btn {
	color: $colour-text;
	border-radius: 0.5rem;
	padding: 0.4em 0.6rem;
	transition: ease 300ms;
	padding-left: 0.2rem;
}

// Navigation buttons
#navigation-buttons {
	display: flex;
	margin-left: $element-spacing;
	align-items: center;

	i {
		font-size: 18px;
		cursor: pointer;


		&:hover {
			color: $colour-primary;
		}
	}

	.tb-back-btn {
		margin-right: 1rem;
	}
}

.top-nav-logo {
	display: none;
}

@media only screen and (min-width: $breakpoint-md) {
	.total-aum-container {
		display: block;
	}

	.tb-divider {
		display: block;
	}

	.profile-dropdown-container {
		display: block;
	}
	
	#topbar-container {
		// height: 100px;
		position: relative;
		justify-content: flex-start;
		box-shadow: none;
		width: 100%;
		padding: 0;
		background-color: transparent;
		grid-column: 3 / 15;

		#topbar-right-content {
			// margin-right: 0;
			height: 50px;
			margin-left: auto;
		}

		#searchbar {
			// Old searchbar input styles. Will keep here just in case.
			width: 200px;
			margin-top: 0;
			position: relative;
			display: flex;
			margin-left: 10px;

			i {
				font-size: 1.2em;
				// margin-left: 10px;
			}

			input {
				width: 100%;
				border: none;
				padding: 0.7em 1em;
				background-color: $colour-form-input;
				font-size: 0.7em;
				border-radius: 5px;
				outline: none;
				transition-duration: 300ms;
				padding-left: 35px;

				&::placeholder {
					color: #fff;
					opacity: 0.2;
				}

				&:focus {
					background-color: $colour-form-focus;
					color: $colour-form-focus-text;

					&::placeholder {
						color: $colour-form-focus-text;
						opacity: 0.7;
					}
				}
			}
		}

		#search-btn {
			display: none;
			font-size: 0.8em;
			margin-left: 2em;
			background-color: $colour-primary;
			transition: ease-in-out 200ms;
			font-weight: 600;
			border: 3px solid $colour-primary;

			i {
				font-size: 1.4em;
			}

			&:hover {
				background-color: rgba($colour-primary, 0.2);
			}

			&:active {
				opacity: 0.5;
			}
		}

		#navigation-buttons {
			display: flex;
			align-items: center;

			i {
				cursor: pointer;
				&:hover {
					color: $colour-primary;
				}
			}

			.tb-back-btn {
				margin-right: 1em;
			}
		}

		#mobile-menu-btn,
		#mobile-search {
			display: none;
		}

		.profile-detail-container {
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			position: relative;
			pointer-events: all;
		}

		.profile-dropdown-container {
			position: relative;
		}
	}

	.top-nav-logo {
		display: block;
	}
}

@media screen and (max-width: $breakpoint-md) and (width: $breakpoint-md) {
	#searchbar {
		display: none !important;
	}
	.banner-container {
		display: none;
	}
}

@media screen and (max-width: $breakpoint-mobile) {
	#top-nav-banner {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;

		.top-nav-inner {
			height: 100%;

			#topbar-container {
				height: 100%;
			}
		}

		.profile-img-wrapper {
			width: 40px;
			height: 40px;
		}
	}
}