@import "@threeskye/core-components/dist/styles/Variables.scss";

.sab-tooltip{
	width: 100px;
}

.sab-option {
	width: 280px;
	padding: 12px 10px;
	cursor: pointer;
	font-size: 1rem;
	border-radius: 6px;

	&:hover {
		background-color: rgba(#424E7B, 0.8);
	}

	&.active {
		background-color: #424E7B;
	}

}
	
.sab-icon{
	color: $colour-primary;
	margin-right: 10px;
	font-size: 1.5rem !important;
	transform: translateY(5px);
}

.sab-options-container {
	width: fit-content;

	// Styles for the sidebar add order menu. Feel free to nest these in a specific id/class if they are breaking styles elsewhere.
	display: flex;
	flex-direction: column;
	align-items: flex-end;

	.tag-group {
		background-color: $colour-secondary;
		border-radius: 6px;
		box-shadow: 0 0 8px rgba(#000000, 0.2);

		&:hover {
			opacity: 1;
			background-color: lighten($colour-secondary, 10%) !important;
		}
	}

	&.popup-element-outer {
		background-color: transparent !important;
		box-shadow: none;
	}

	.tooltip-popup-wrapper {
		background-color: transparent !important;
	}

	.orders-linked-row-small {
    	width: 100%;
    	grid-template-columns: 40px 160px 100px 50px 50px auto !important;
		gap: 8px;
		background-color: transparent;
		border: none;
		color: #1a2038;

		div {
			&:last-of-type {
				text-align: right;
			}
		}
		 
		.ca-token-outer {
			color: #fff;
		}
	}

	.individual-match.order {
		padding: 4px 12px !important;
	}

	.not-found {
		padding: 12px 10px;
		color: hsl(0,0%,60%);
		text-align: center;
		box-sizing: border-box;
		background-color: #e6f3ff !important
	}
}

.sab-option-content {
	transform: translateY(-5px);
}

.link-order-selectable-account-list {
	background: white;
	border-radius: 5px; 
	color: $colour-account;
	
	.account-token-option {
		padding: 10px; 
		display: flex;
		align-items: center;
		gap: 10px;
		&:hover {
			background-color: rgba(#30395A, 0.1);
			
		}
	}
}