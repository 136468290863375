// CONTENT GRID
$content-gutter-margin: 1.5rem;
$content-top-margin: 1rem;
$page-x-margin: 1rem;
$content-left-margin: 1.5rem;
$content-right-margin: 1.5rem;

// CONTENT
$content-max-large: 1784px;
$content-max: 1000px;
$content-min: 680px;

// MENU SIDE NAV
$menu-side-nav: 64px;

// SIDEBARS
$sidebar-width: 344px;
$sidebar-left-min: 344px;
$sidebar-left-max: 344px;
$sidebar-right-min: 384px;
$sidebar-right-max: 512px;
$sidebar-right-expanded: 472px;
$sidebar-right-expanded-full: 1200px;
$thin-sidebar-width: 260px;

// BREAKPOINTS
$breakpoint-mobile: 768px;