@import "./LayoutVariables.scss";
@import "../themes/ColoursRedo.scss";


// TOP NAV BANNER
#top-nav-banner {
	background-color: $colour-dark;
	grid-area: top-nav-banner;
	padding: 0 $page-x-margin;
	box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
	z-index: 100;
	display: flex;
	align-items: center;
	
	.top-nav-inner {
		display: flex;
		align-items: center;
		width: 100%;
		height: 32px;
		// max-width: 1864px;
	}
	.top-nav-logo {
		height: auto;
		width: 62px;
	}

	@media screen and (max-width: $breakpoint-mobile) {
		height: 56px;
	}
}