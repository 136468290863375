$sizes: 'xs' 4px,'s' 8px, 'm' 16px, 'l' 24px, 'xl' 32px, 'xxl' 40px;

@each $size, $i in $sizes {
	.flex-wrapper-gap-#{$size} {
		gap: $i;
	}	
}
@each $size, $i in $sizes {
	.flex-wrapper-column-gap-#{$size} {
		column-gap: $i;
	}	
}
@each $size, $i in $sizes {
	.flex-wrapper-row-gap-#{$size} {
		row-gap: $i;
	}	
}