@import "@threeskye/core-components/dist/styles/Variables.scss";


.checkbox-field {
	display: flex;
	align-items: center;
	font-size: 16px;
	margin-bottom: 10px;

	i {
		color: $colour-primary;
		margin-right: 5px;
		transition: 200ms ease;
		position: relative;
		font-size: inherit;
	}

	&:hover {
		i {
			opacity: 0.6;

			&:before {
				opacity: 0.6;
			}
		}
	}
}

.checkbox-field:hover {
	cursor: pointer;
}