@import "@threeskye/core-components/dist/styles/Variables.scss";

// VARIABLES
$form-input-border: 3px;
$form-font-size: 14.4px;
$form-input-border-radius: 4px;
$form-input-margin: 0.5rem 0;

@mixin input-styling() {
	border-radius: $form-input-border-radius;
	color: $colour-form-text;
	font-size: $form-font-size;
	width: 100%;
	margin: $form-input-margin;
	background-color: $colour-form-input;
	resize: vertical;
	border: none;
	border-image: initial;
	border-radius: 4px;
	padding: 0.5rem;
	outline: none;
	font-family: $font-primary;

	&::placeholder {
		color: $colour-form-text;
		opacity: 0.2;
	}

	&:focus {
		background-color: $colour-form-focus;
		color: $colour-form-focus-text;

		&::placeholder {
			color: $colour-form-focus-text;
			opacity: 0.4;
		}
	}
}

@mixin truncateText {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}