@import "../../../themes/3skye.scss";
@import "@threeskye/core-components/dist/styles/Variables.scss";
@import "../../../layout/LayoutVariables.scss";

.sbe-icon {
	font-size: 20px;
	color: #fff;
	opacity: 0.5;

	&:hover {
		opacity: 1;
	}

	&.icon-active {
		color: $colour-primary;
		opacity: 1;
	}

	@media screen and (max-width: $breakpoint-mobile) {
		font-size: 24px !important;
	}
}