@import "./LayoutVariables.scss";
@import "../themes/ColoursRedo.scss";

// SIDE NAV WITH ICONS
#side-nav-bar {
	z-index: 1;
	background-color: $colour-dark;
	box-shadow: -1px 0px 6px rgba(0, 0, 0, 0.25);
	grid-area: side-nav-bar;
	padding: $page-x-margin;
	display: flex;
	flex-direction: column;
	align-items: center;
	
	.side-nav-bar-inner{
		width: 24px;
		height: 100%;
	}
	
	p {
		display:none;
	}

	&.right {
		grid-area: side-nav-right;
	}
}

// Mobile styles
@media screen and (max-width: $breakpoint-mobile) {
	#side-nav-bar {
		display: none;

		// Bottom Nav
		&.right {
			display: flex;
			position: fixed;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 72px;
			align-items: center;
			flex-direction: row;

			.side-nav-bar-inner {
				width: 100%;
				height: auto;
				display: flex;
				justify-content: center;
				gap: 3rem;

				.side-nav-icon {
					margin: 0;
					font-size: 1.8rem;

					&.active-icon {
						&:after {
							width: 24px;
							height: 3px;
							top: -24px;
							left: 0;
						}
					}
				}
			}
		}
	}
}