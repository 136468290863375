@import "@threeskye/core-components/dist/styles/Variables.scss";

.select-clear-icon {
	color: #fff;
	opacity: 0.5;
	display: flex;
	padding: 0;
	transition: color 150ms ease 0s;
	box-sizing: border-box;
	cursor: pointer;
	font-size: 19px;

	&:hover {
		opacity: 1;
	}
}