@import "../../../../themes/3skye.scss";
@import "@threeskye/core-components/dist/styles/Variables.scss";

// ============================
// *** DARK THEME ***
// ============================
.dark-theme {
	.notification-li {
		// background-color: rgba(#282F4B, 0.5);
		background-color: rgba($colour-secondary, 0.7);

		&:hover {
			// background-color: rgba(#282F4B, 0.7);
			background-color: #53639d;

		}
	}

	.notification-unread {
		// background-color: #282F4B;
		background-color: #4f5d93;
	}

	// @media (min-width: 1100px) {
	.attention-module-expanded {
		.dbm-expand {
			color: $colour-secondary;
		}
	}

	// }

}

// ============================
// *** LIGHT THEME ***
// ============================
.light-theme {
	.notification-li {
		background-color: $light-colour-secondary;

		&:hover {
			background-color: $light-colour-secondary;
		}
	}

	.notification-unread {
		background-color: #fff;
	}

	// @media (min-width: 1100px) {
	.attention-module-expanded {
		.dbm-expand {
			color: $colour-secondary;
		}
	}

	// }

}

// ============================
// *** STYLES ***
// ============================

.notification-module {
	max-height: 400px;
	overflow: auto;
}

.notification-li {
	padding: 1rem;
	padding-right: 0.5rem;
	display: flex;
	gap: 10px;
	justify-content: flex-start;
	border-radius: 4px;
	align-items: center;
	margin-bottom: 12px;
	cursor: pointer;
	transition: ease-out 200ms;

	// Token styles for change requests etc.
	.ca-token-outer.ca-token-small {
		height: 20px;

		.ca-token-content {
			font-size: 12px;
			width: calc(100% - 26px);
		}

		.ca-type-indicator {
			width: 22px;

			i {
				font-size: 14px;
			}
		}
	}

	&:last-of-type {
		margin-bottom: 0;
	}

	i {
		font-size: 20px;

		&.notification-type-icon {
			color: $colour-primary;
		}

		// icon type colouring
		&.birthday {
			color: #47C2D8;
		}

		&.note,
		&.clientNote,
		&.file-note {
			color: #EB9D74;
		}

		&.workspace-note {
			color: #FAC35E;
		}

		&.meeting {
			color: #9898CB;
		}

		&.assembled-change {
			margin-bottom: -6px;
			transform: scale(1.1);
		}

		&.change-min-Notification {
			transform: scale(0.8);
			
			.cr-icon-path {
				fill: grey;
			}

			&:hover {
				.cr-icon-path {
					&.approved {
						fill: #30C9AC
					}
					&.feedback {
						fill: #0D8EFD
					}
					&.rejected {
						fill: #F7A43D
					}
				}
			}
		}

		&.change,
		&.workflow {
			color: $colour-primary;
			transform: scale(1.1);
		}

		&.call,
		&.phoneCall,
		&.phone {
			color: #30C9AC;
		}
		&.email-template {
			color: #208BA5;
		}
	}

	.delete-notification {
		font-size: 1.2rem;
		opacity: 0.4;

		&:hover {
			opacity: 1;
		}
	}

	.tagged-acc {
		cursor: pointer;

		&:hover {
			text-decoration: underline;
		}
	}

	&:hover {
		.notification-actions {
			i {
				opacity: 0.5;
				transform: translateY(0);

				&:hover {
					opacity: 1;
				}
			}
		}

		&.notification-pinned {
			.pinned-icon {
				opacity: 1;
			}
		}
	}
}

.notification-unread {
	.notification-li-title {
		font-weight: 600;
	}

	.notification-li-subtitle {
		font-weight: 600;
		opacity: 1;
		padding-left: 10px;
		position: relative;

		&:before {
			content: "";
			background-color: $colour-primary;
			border-radius: 100px;
			width: 6px;
			height: 6px;
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
		}
	}
}

// Expandable notification styles
.notification-expandable {
	padding: 0;
	flex-direction: column;
	align-items: flex-start;
	gap: 0;
	cursor: default;

	&.notification-expanded {
		background-color: rgba($colour-secondary, 0.7);

		&:hover {
			background-color: rgba($colour-secondary, 0.7);
		}

		.notification-expand-icon {
			transform: rotate(-180deg);
		}

		.expandable-inner {
			border-bottom-right-radius: 0;
			border-bottom-left-radius: 0;
		}
	}

	.expandable-inner {
		padding: 1rem 1rem;
		padding-right: 0.5rem;
		cursor: pointer;
		display: flex;
		grid-gap: 10px;
		gap: 10px;
		justify-content: flex-start;
		border-radius: 4px;
		align-items: center;
		width: 100%;
		transition: 200ms ease;

		&:hover {
			background-color: rgba(#53639d, 0.3);
		}
	}

	.expandable-content {
		padding: 1rem 1rem;
		padding-left: 30px;
		padding-right: 0.5rem;
		width: 100%;
	}

	.notification-li-subtitle {
		display: flex;
		align-items: center;
		gap: 4px;
		opacity: 1;
	}

	.notification-expand-icon {
		opacity: 0.5;
		transition: 200ms;
		font-size: 18px;
	}

	.divider {
		margin: 0 auto;
		width: calc(100% - 16px);
	}
}

.notification-li-content {
	width: 78%;
	position: relative;
	overflow: hidden;
	display: flex;
	gap: 8px;
	flex-direction: column;
}

.notification-li-title {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	font-size: 16px;
	font-weight: 500;

	i {
		&.expanded-icon {
			transform: translateY(4px);
		}
	}
}

.notification-li-subtitle {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	font-size: 12px;
	opacity: 0.7;

	&.review-type {
		opacity: 1;

		.ca-token-value {
			width: auto !important;
		}
	}
}

.notification-li-date {
	font-size: 0.6rem;
	margin-top: 0.4rem;
}

.attention-module {
	display: block;
}

.attention-module-expanded {
	display: none;
}

.alerts-module {
	grid-column: 1 / span 3;
	grid-row: 4;
	height: 300px;
}

.attention-module {
	grid-column: 1 / span 3;
	grid-row: 3;
	height: 300px;
}

.alerts-module {
	grid-column: 1 / span 6;
	grid-row: 6 /span 5;
	height: inherit;
}

.attention-module {
	height: inherit;
	grid-row: 1 / span 5;
	grid-column: 1 / span 6;

}

.attention-module-expanded {
	grid-row: 1 / span 10;
	grid-column: 1 / span 6;
	max-height: inherit;
}

.expanded {
	display: none;
}

.attention-module-expanded.expanded {
	display: block;
}

.notification-pinned {
	.pinned-icon {
		transform: translateY(0);
		opacity: 1;
		color: $colour-primary;
	}
}

.pinned-icon {
	&:hover {
		color: $colour-primary;
	}
}

.delete-icon {
	&:hover {
		color: $colour-warning;
	}
}

.notification-actions {
	display: flex;
	gap: 8px;
	align-items: center;
	margin-left: auto;

	i {
		transform: translateY(2px);
		transition: 200ms ease;
		opacity: 0;
	}
}