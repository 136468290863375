@import "../../themes/3skye.scss";
@import "@threeskye/core-components/dist/styles/Variables.scss";

.toast-list-container {
	visibility: hidden;
	position: fixed;
	bottom: -20px;
	left: 0;
	z-index: 3000;
	opacity: 0;
	transition-duration: 400ms;
	width: 100%;
}

.toast-container {
	background-color: $colour-primary;
	color: #fff;
	padding: 0.4em 1.4rem;
	box-shadow: 0 2px 7px rgba(#000, 0.4);
	margin-top: 0.6rem;
}

.toast-content {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0.4em 0;
}

.toast-message {
	font-size: 0.9rem;
	margin-right: 2rem;
	font-weight: 600;
}

.toast-close {
	cursor: pointer;
	font-size: 0.9rem;
	width: 28px;
	height: 28px;
	display: flex;
	border-radius: 100px;
	align-items: center;
	justify-content: center;
	background-color: rgba($colour-background, 0.05);

	&:hover {
		background-color: rgba($colour-background, 0.5);
	}
}

.toast-fade-in {
	visibility: visible;
	opacity: 1;
    bottom: 0;
}

// @media (min-width: $breakpoint-md) {
	.toast-list-container {
		top: 80px;
		right: -40px;
		display: inline-block;
		width: fit-content;
		left: unset;
		bottom: auto;
	}

	.toast-content {
		padding: 0 0;
	}

	.toast-container {
		border-radius: 6px;
	}

	.toast-fade-in {
		bottom: auto;
		right: 20px;
	}
// }
