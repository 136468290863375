@import "../../themes/3skye.scss";
@import "@threeskye/core-components/dist/styles/Variables.scss";

.record-advice-content {
	hr {
		background-color: #9898CB;
		margin: 10px 0;
	}

	li {
		margin-bottom: 20px;
		position: relative;
		padding-left: 22px;
		font-size: 15px;
		word-wrap: break-word;

		&:before {
			content: "";
			width: 9px;
			height: 9px;
			border-radius: 50%;
			background-color: #535F88;
			position: absolute;
			left: 0;
			top: 6px;
		}
	}

	.record-advice-header {
		font-weight: 600;
		font-size: 24px;
		margin-bottom: 10px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		i {
			color: #535F88;
			cursor: pointer;
		}
	}
}

.record-advice-mobile {
	display: none;
	margin: 0 10px;
    margin-bottom: 10px;
	background-color: transparent;
	padding: 20px 10px;
	border: 1px solid rgba(#6f7ba7, 0.6);
	border-left: none;
	border-right: none;
	border-radius: 0;
	width: 100%;

	hr {
		margin: 10px !important;
		display: none;
	}

	i {
		display: none;
	}
}

.record-advice-active {
	display: block;
	animation: fadeIn 200ms ease;
}

// @media (min-width: 1061px) {
	.record-advice-mobile {
		display: none;	
	}
// }

@keyframes fadeIn {
	0% {
		opacity: 0;
		transform: translateY(10px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}