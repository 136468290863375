@import "../../../themes/3skye.scss";
@import "@threeskye/core-components/dist/styles/Variables.scss";
@import "../../../styles/Mixins.scss";

// MIXINS
@mixin tagLabel {
	font-size: 0.8rem;
	opacity: 0.7;
	margin-left: 0.5rem;
}

#create-event-modal {
	.modal-main-header {
		position: absolute;
		top: 24px;
		max-width: none;
		width: 80%;
	}

	.modal-inner-container {
		padding: 3em 1.5em;
	}
}

.event-notes {
	.tta-editor {
		margin-bottom: 0;
		max-height: 300px;
		overflow: auto;
	}

	&.email-template-editor {
		.slate-editor-inner {
			.editor-toolbar {
				position: sticky;
				z-index: 4;
				opacity: 1;
				top: -17px;
			}
		}

		&.mobile-view {
			.slate-editor-inner {
				.editor-toolbar {
					top: 56px;
				}
			}
		}
	}
}

.event-type-header {
	font-size: 0.9rem;
	margin-top: 1.3rem;
	margin-bottom: 0.5rem;
	width: 100%;
}

.event-modal-advice {
	margin-top: 20px;
}

.event-modal-attachment {
	transform: rotate(90deg);
}

// @media (min-width: 555px) {
.event-modal-advice {
	margin: 0 !important;
}

// }

.event-type-list {
	li {
		display: inline-block;
		margin-right: 1.5rem;
		color: $colour-primary;
		opacity: 0.4;
		cursor: pointer;
		position: relative;

		&:hover {
			opacity: 1;
		}
	}

	.active-event-type {
		opacity: 1;
		font-weight: 700;

		&::before {
			opacity: 0.24;
		}

	}
}

.tagged-name {
	position: absolute;
	left: 0.8rem;
	top: 2.4rem;
	font-size: 0.8rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: $colour-primary;
	color: $colour-text;
	padding: 0.1em 0.8rem;
	border-radius: 10px;
	cursor: pointer;
	transition: 200ms;

	i {
		font-size: 15px;
		margin-left: 5px;

		&:hover {
			opacity: 0.2;
		}
	}
}

.event-modal-btn {
	padding: 0.5em 2.5em !important;
	border: none;
	color: $colour-text;

	&:hover {
		opacity: 0.5;
	}
}

.event-type-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	hr {
		margin-top: 0;
	}
}

#modal-finish-btn {
	background-color: $secondaryColour-1;
	margin-right: 1rem;
}

#modal-draft-btn {
	background-color: rgba($colour-text, 0.4);
}

.event-modal-autocomplete {
	display: none;
	width: auto;
	background-color: $colour-text;
	color: $colour-primary;
	position: absolute;
	border-radius: 6px;
	font-size: 1rem;
	top: 100%;
	left: 0;
	z-index: 1000;

	li {
		cursor: pointer;
		padding: 1em 1.4rem;
		font-weight: 500;

		&:hover {
			background-color: rgba($colour-secondary, 0.2);
		}
	}
}

.date-time-input-container {
	display: flex;
	// justify-content: space-between;
	align-items: center;
	gap: 8px;
}

.first-of-section {
	padding-left: 1.5rem;

	&::before {
		content: "";
		width: 1px;
		height: 20px;
		background-color: #fff;
		opacity: 0.6;
		position: absolute;
		margin-left: -1.5rem;
	}

	&:hover {
		&::before {
			opacity: 0.24;
		}
	}

}

.create-event-modal {
	.form-row {
		margin-bottom: 5px;
	}

	hr {
		margin: 14px 0;
	}
}

.help-text {
	font-size: 0.8rem;
	color: rgba(#fff, 0.4);
	width: 100%;
	height: 30px;
	display: flex;
	align-items: center;
}

// VALIDATION STYLES. MOVE TO VALIDATION SHEET. -CM
.invalid-field {

	.token-field__control,
	.date-field-outer,
	.time-field-outer,
	.tta-editor {
		border: 2px solid $colour-warning !important;
		background-color: rgba($colour-warning, 0.3) !important;
		color: #fff !important;

		input {
			border: none !important;
			background-color: transparent !important;
			color: #fff !important;

			&:focus {
				color: #fff !important;
			}

			&::placeholder {
				color: #fff !important;
				opacity: 0.4;
			}
		}
	}

	.tta-editor {
		margin-bottom: 0.4rem;
	}

	.invalid-field-message {
		display: flex;
		align-items: center;

		i {
			font-size: 1rem;
			margin-right: 5px;
		}
	}
}

#create-event-modal {
	.date-field-outer {
		width: 100%;
	}

	@media screen and (max-width: $breakpoint-md) {
		display: block;

		.date-field-outer {
			flex-grow: 1;
		}

		.modal-inner-container {
			padding-top: 5.5rem;
		}

		.modal-main-header {
			font-size: 1.5rem;
			width: calc(100vw - 60px);
			top: 48px;
		}

		.modal-control-buttons {
			top: 3px;
		}
	}
}

#event-form {
	display: flex;
	flex-direction: column;
	gap: 1rem;

	.modal-uploads-container {
		margin: 0;

		&.extra-padding-bottom {
			padding-bottom: 36px;
		}
	}

	.form {
		display: flex;
		flex-direction: column;
		gap: 1rem;
	}

	.date-field-outer {
		height: 36px;
	}

	.token-field,
	.date-field-outer,
	.time-field-outer,
	.checkbox-container,
	.checkbox-field {
		margin: 0;
	}

	.form-row {
		margin: 0;

		input {
			margin: 0;
		}
	}

	.token-field__control--is-focused {
		margin: 0 !important;
	}
}

.event-modal-email-template-tag-and-select-container {
	display: flex;
	flex-direction: row;
	gap: 5px;
	width: 100%;
	align-items: center;
	height: 36px;
	border-radius: 6px;

	.template-label {
		background-color: #424E7B;
		height: 24px;
		border-radius: 6px;
		display: flex;
		align-items: center;
		padding: 0px 6px;
	}
}

.event-draft-fixed-buttons {
	bottom: 0px;
	position: fixed;
	width: 100%;
	background-color: #1a2038;
	padding: 12px 0px;

	&.mobile-mode {
		bottom: 65px;
	}
}

.orders-linked-row-small {
	background-color: rgba(48, 57, 90, 0.9019607843);
	border: solid 1.5px #3a4360;
	border-radius: 5px;
	display: grid;
	grid-template-columns: calc(100% - 139px) 34px 45px 30px 30px !important;
	align-items: center;
	padding: 7px 0px;
	margin-bottom: 5px;

	.logo {
		padding: 0px 5px;
	}

	.info {
		opacity: 0.4;
		font-size: 19px;
		padding: 0px 5px;
		cursor: pointer;
	}

	.delete {
		font-size: 19px;
		color: #E35953;
		cursor: pointer;
		border-left: solid 1.5px #4f556b;
		padding: 0px 5px;
		// #4f556b
	}
}

.tooltip-popup-wrapper {
	background-color: #3c4874;
	border-radius: 8px;

	.orders-info-icon-table {
		display: flex;
		flex-direction: column;
		gap: 10px;

		.orders-info-icon-table-row {
			display: grid;
			grid-template-columns: 85px 85px minmax(50px, 80px) 50px minmax(60px, 85px) minmax(60px, 85px) minmax(60px, 110px);
			gap: 5px;
			color: #fff;

			.orders-info-icon-table-th {
				font-size: 11px;
				opacity: 0.6;

				&.right {
					text-align: right;
				}
			}

			.orders-info-icon-table-cell {
				font-size: 14px;

				&.right {
					text-align: right;
				}
			}

		}
	}
}

.link-order-email-advice-icon {
	display: flex;
	align-items: center;
	gap: 3px;
	cursor: pointer;

	i {
		font-size: 16px;
		padding-bottom: 1.5px;
	}

	&.advice-active {
		i {
			color: #30C9AC;
		}
	}

	&.advice-inactive {
		opacity: 0.5 !important;

		&:hover {
			opacity: 1 !important;

			i {
				color: #0D8EFD;
			}
		}

	}

	p {
		font-weight: 600;
		font-size: 16px;
	}
}