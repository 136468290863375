@import "@threeskye/core-components/dist/styles/Variables.scss";

.popup-outer {
	width: auto;

	.popup-outer-inner {
		display: flex;
	}

	&.popup-relative {
		position: relative;
	}
}

.popup-element-outer {
	background-color: $colour-secondary;
	border-radius: 6px;
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
	transition: top 300ms, opacity 200ms;
	z-index: 100;

	i {
		font-size: 0.9em;
		cursor: pointer;
	}
}
