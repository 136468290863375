@import "@threeskye/core-components/dist/styles/Variables.scss";
@import "../../themes/ColoursRedo.scss";

// ============================
// *** DARK THEME ***
// ============================
.dark-theme {
	.details-container {
		i {
			color: $colour-primary;
		}
	}

	.update-label-container {
		.label {
			color: $colour-primary;
		}
	}
	
	.status-popup, .detail-popup {
		background-color: $colour-secondary;

		&:after {
			border-top: 7px solid $colour-secondary;
		}
	}
}

// ============================
// *** LIGHT THEME ***
// ============================
.light-theme {
	.details-container {
		i {
			color: $colour-primary;
		}
	}

	.update-label-container {
		.label {
			color: $colour-primary;
		}
	}
	
	.status-popup, .detail-popup {
		color: $colour-light-accent-contrast;
		background-color: $colour-light-accent;

		&:after {
			border-top: 7px solid $colour-light-accent;
		}
	}
}

// ============================
// *** STYLES ***
// ============================

@mixin ellipseOverflow {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.event-detail-updates-container {
	margin-bottom: -15px;
}

.event-detail-updates {
	display: flex;
	flex-wrap: wrap;
	margin-top: 10px;
}

.event-detail-update-item {
	margin-bottom: 20px;
	width: 100%;
	word-break: break-all;
}

.details-container {
	display: flex;
	flex-wrap: wrap;

	i {
		display: none;
	}

	.arrow-icon {
		margin-right: 6px;	
	}
}

.multi-details {
	// Toggle the opacity of the old + new value
	// &:hover {
	// 	.detail-original {
	// 		opacity: 1;
	// 	}

	// 	.detail-update {
	// 		opacity: 0.2;
	// 	}
	// }
}

.detail-update-container {
	position: relative;
	max-width: 100%;


	&:hover {
		.detail-popup {
			display: block;
		}
	}

	&.single-detail-item {
		width: 100%;
	}
}

.update-label-container {
	width: 100%;

	.label {
		margin-bottom: 7px;
		font-size: 0.8rem;
		margin-right: 5px;
	}
}

.detail-original {
	opacity: 0.2;
	transition: 500ms ease;
	margin-right: 7px;
	cursor: default;
}

.detail-update {
	cursor: default;
	transition: 500ms ease;
	word-wrap: break-word;

	&.detail-deleted {
		opacity: 0.4;
	}
}

.status-container {
	// Display none until this is hooked up and working
	display: none;
	position: relative;
	z-index: $layer-1;
	font-weight: 600;
	
	.update-pending {
		color: #E6B862;
	}

	.update-complete {
		color: $colour-success;
	}

	i {
		font-size: 0.9rem;
		cursor: pointer;
	}

	&:hover {
		.status-popup {
			display: block;
		}
	}
}

.status-popup, .detail-popup {
	display: none;
	position: absolute;
	top: -50px;
	left: 50%;
	transform: translateX(-50%);
	width: fit-content;
	white-space: nowrap;
	padding: 10px 14px;
	border-radius: 6px;
	text-align: center;
	z-index: $layer-2;
	box-shadow: 0 0 2px rgba(#000, 0.1);

	&::after {
		content: "";
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		bottom: -5px;
		width: 0;
		height: 0;
		border-left: 7px solid transparent;
		border-right: 7px solid transparent;
	}
}

.detail-update-label {
	// display: flex;
	// align-items: center;
	margin-bottom: 5px;
	position: relative;
	overflow: hidden;
	width: 100%;
	white-space: nowrap;
	text-overflow: ellipsis;

	i {
		// position: absolute;
		// left: -5px;
		transform: translateY(2.5px);
		margin-top: 4px;
		font-size: 15px;
		color: $colour-primary;
		margin-right: 3px;
	}
}

.bank-acc-change-wrapper {
	display: flex;
	flex-wrap: wrap;
	background-color: rgba($colour-secondary, 0.8);
	padding: 6px;
	border-radius: 6px;

	span {
		width: 100%;
		padding-bottom: 4px;
		margin-bottom: 4px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		border-bottom: 1px solid #9898cb4d;

		&:last-of-type {
			margin-right: 0;
			border-right: none;
		}

		&:nth-child(4) {
			border-right: 1.5px solid #9898cb4d;
			margin-right: 7px;
			padding-right: 7px;
		}

		&:nth-child(4), &:nth-child(5) {
			width: auto;
			margin-bottom: 4px;
			padding-bottom: 0;
			border-bottom: none;
		}
		&:nth-child(6) {
			padding-top: 4px;
			border-top: 1px solid #9898cb4d;
		}
	}
}

// @media (min-width: $breakpoint-md) {
	.event-detail-update-item {
		width: auto;
		// margin-right: 50px;
	}

	.details-container {
		i {
			display: block;
		}
	}

	// .detail-original, .detail-update {
	// 	max-width: 200px;
	// 	@include ellipseOverflow();
	// }
// }