@import "../../themes/3skye.scss";
@import "@threeskye/core-components/dist/styles/Variables.scss";

#mobile-menu-container {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 3000;
	width: 100%;
	visibility: hidden;
	background-color: rgba(#000, 0.4);
	opacity: 0;
	transition: 400ms ease-in-out;

	&.mm-slide-overlay {
		opacity: 1;
		visibility: visible;
	}
}

#mobile-menu {
	width: 90%;
	overflow: hidden;
	max-width: 300px;
	height: 100vh;
	padding: 2em 0;
	align-items: center;
	justify-content: center;
	background-color: $colour-background;
	box-shadow: 2px 0 5px rgba(#000000, 0.2);
	transition: ease-in 300ms;
	margin-left: -300px;

	.profile-img-wrapper {
		width: 48px;
		height: 48px;
	}

	&.mm-slide-animation {
		margin-left: 0;
	}

	#side-nav-links {
		display: none;
	}

	#side-nav-links li, #side-nav-footer {
		i {
			margin: 0 32px;
		}

		.sidebar-link {
			align-items: center;
		}
	}
}

.mm-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: 0 0.9rem;
	margin-bottom: 3rem;
}

#mm-logo {
	width: 70px;
	height: auto;
}

#mm-close {
	cursor: pointer;
}

.mm-profile-container {
	display: flex;
	align-items: center;
	padding: 0 0.9rem;
}

.mm-profile-details {
	margin-left: 1rem;
	width: 130px;

	h2 {
		font-weight: 500;
		font-size: 1.3rem;
	}

	p {
		font-size: 0.8rem;
		opacity: 0.4;
	}
}

@media (min-width: $breakpoint-md) {
	#mobile-menu-container {
		display: none;

		&.mm-slide-animation {
			display: none;
		}
	}
	
}
