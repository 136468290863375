@import "@threeskye/core-components/dist/styles/Variables.scss";
@import "../../themes/ColoursRedo.scss";

// ============================
// *** DARK THEME ***
// ============================
// .dark-theme {}

// ============================
// *** LIGHT THEME ***
// ============================
// .light-theme {}

// ============================
// *** STYLES ***
// ============================
#emtf-wrapper {
	position: relative;

	input {
		transition: 200ms ease;
		width: 100%;
		margin: 0;
		position: relative;
		padding-right: 56px;

		&::placeholder {
			text-transform: capitalize;
		}
	}
}

@media screen and (max-width: 768px) and (min-width: 626px) {
	#emtf-template-wrapper {
		min-width: 180px !important;
		display: flex;
		justify-content: right;
	}
}

@media screen and (max-width: $breakpoint-sm) {
	#emtf-wrapper {
		width: 100%;
	}

	#emtf-template-wrapper {
		width: auto;

		.template-label {
			background-color: #424E7B;
			height: 24px;
			border-radius: 6px;
			display: flex;
			align-items: center;
			padding: 0px 6px;
		}
	}
}