@import "@threeskye/core-components/dist/styles/Variables.scss";
@import "../../themes/ColoursRedo.scss";

.status-tag {
	display: inline-block;
	padding: 3px 10px;
	border-radius: 20px;
	background-color: $colour-success;
	color: #fff;
	font-size: 14px;
	font-weight: 400;
	text-transform: capitalize;

	&.open,
	&.completed {
		background-color: $colour-success;
	}

	&.inactive,
	&.rejected {
		background-color: $colour-warning;
	}

	&.cancelled {
		background-color: #424E7B;
	}

	&.closed {
		background-color: #E35953;
	}

	&.prospect {
		background-color: $colour-warning;
	}

	&.in-progress {
		background-color: #f7a43d;
	}

	&.awaiting-client {
		background-color: #0d8efd;
	}


	&.onboarding {
		&.submitted, &.in-progress, &.mobile-verified, &.text-sent, &.email-verified, &.email-sent {
			background-color: #0d8efd;
		}

		&.text-re-sent, &.email-re-sent {
			background-color: #0d8efd;
			text-transform: none;
		}

		&.cancelled, &.verification-fail  {
			background-color: #E35953;
		}

		&.expiring-soon, &.email-expired, &.text-expired {
			background-color: #f7a43d;
		}
	}
}