@import "@threeskye/core-components/dist/styles/Variables.scss";

.no-results-message {
	width: 100%;
	text-align: center;
	animation: fadeMessageIn 500ms ease-in-out forwards;

	i {
		color: $colour-primary;
		font-size: 70px;
		margin-bottom: 11px;
	}
}

@keyframes fadeMessageIn {
	0% {
		opacity: 0;
		transform: translateY(7px);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}