@import "../../themes/3skye.scss";
@import "@threeskye/core-components/dist/styles/Variables.scss";
@import "../../themes/ColoursRedo.scss";

// ============================
// *** STYLES ***
// ============================
.side-nav-icon {
	cursor: pointer;
	opacity: 0.5;
	transition-duration: 300ms;
	margin-bottom: 2rem;
	position: relative;

	&:after {
		content: "";
		height: 100%;
		background-color: #0d8efd;
		position: absolute;
		top: 0;
		left: -20px;
		border-radius: 10px;
		width: 0;
		transition: 0;
	}
	
	&.active-icon {
		opacity: 1;
		
		&:after {
			width: 3px;
		}
	}

	.sni-counter {
		position: absolute;
		right: -10px;
		top: -7px;
		border-radius: 50%;
		width: 21px;
		height: 21px;
		background-color: #0d8efd;
		font-size: 11px;
		font-weight: 600;
		color: #fff;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 1;
		border: 2px solid #1a2038;
	}

	i {
		font-size: 1.5rem;
		color: $colour-primary;
		padding: 0;
		text-align: center;
		display: block;
		position: relative;

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	&:hover {
		opacity: 1;
	}

	.tooltip-container {
		&.sidebar-link {
			&:hover {
				.tooltip-popup {
					right: 50%;
					left: unset;
				}
			}
	
			.tooltip-popup {
				right: 46%;
				left: unset;
			}
		}
	}
}