@import "../../../../themes/3skye.scss";
@import "@threeskye/core-components/dist/styles/Variables.scss";

.notification-mini-list {
	width: 100%;
	display: flex;
	flex-direction: column;
	
	.nml-item-wrapper {
		width: 100%;
		padding: 0.8rem 0;
		border-top: 1px solid rgba(#6f7ba7, 0.6);

		&:first-of-type {
			padding-top: 0;
			border-top: none;
		}
	}
}

.mini-notification-wrapper {
	width: 100%;
	display: flex;
	gap: 0.5rem;
	justify-content: space-between;

	.mini-notification-inner {
		width: calc(100% - 29px);
	}

	&:hover {
		.mini-notification-actions {
			i {
				opacity: 0.5;
				transform: translateY(0);
	
				&:hover {
					opacity: 1;
				}
			}
		}
	
		&.notification-pinned {
			.pinned-icon {
				opacity: 1;
			}
		}
	}
	
	.notification-pinned {
		.pinned-icon {
			transform: translateY(0);
			opacity: 1;
			color: $colour-primary;
		}
	}
	
	.pinned-icon {
		&:hover {
			color: $colour-primary;
		}
	}
	
	.delete-icon {
		&:hover {
			color: $colour-warning;
		}
	}
	
	.mini-notification-actions {
		display: flex;
		gap: 8px;
		align-items: center;
		margin-left: auto;
		
		i {
			transform: translateY(2px);
			transition: 200ms ease;
			opacity: 0;
			cursor: pointer;
		}
	}
}