@import "../../themes/3skye.scss";
@import "@threeskye/core-components/dist/styles/Variables.scss";

.no-search-results-message {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	animation: fadeMessageIn 500ms ease-in-out forwards;

	p, h3 {
		width: 100%;
		text-align: center;
	}
	
	i {
		color: $colour-primary;
		font-size: 70px;
		margin-bottom: 11px;
	}
}

#os-overlay {
	display: block;
	align-items: center;
	justify-content: center;
	width: 100vw;
	height: 100vh;
	position: fixed;
	left: 0;
	top: 0;
	background-color: rgba($colour-background, 1);
	z-index: 3000;
}

.os-container {
	width: 100%;
	overflow: auto;
	padding-bottom: 100px;
	height: 100%;
}

.os-header-container,
.os-filter-container,
.os-input-container,
.os-result-section {
	width: 87%;
	max-width: 1000px;
	margin: auto;
}

.os-main-container {
	background-color: $colour-background;
	position: fixed;
	width: 100%;
	padding-top: 100px;
	z-index: 999;
	box-shadow: 0 2px 5px rgba(#000, 0.35);
}

.os-header-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 50px;
}

.os-close-container {
	font-size: 1rem;
	padding-bottom: 100px;
	overflow: auto;
	font-weight: 600;
	background-color: rgba($colour-secondary, 0.12);
	padding: 0.5em 1.5rem;
	border-radius: 20px;
	cursor: pointer;
	transition: ease-in-out 200ms;

	i {
		color: $colour-primary;
		overflow: hidden;
		transition: ease-in-out 200ms;
		text-align: right;
		margin-left: 0.6rem;
	}

	&:hover {
		background-color: rgba($colour-secondary, 0.7);

		i {
			transform: rotate(90deg);
		}
	}

	&:active {
		background-color: rgba($colour-secondary, 1);
	}
}

.os-filter-container {
	padding-bottom: 10px;
}

.os-filter-list {
	display: none;
	list-style-type: none;
}

.os-filter-item {
	font-size: 0.8rem;
	padding: 0.4em 1.3rem;
	padding-right: 1rem;
	margin-bottom: 0.7rem;
	background-color: $colour-primary;
	display: inline-flex;
	align-items: center;
	border-radius: 20px;
	margin-right: 0.7rem;
	cursor: pointer;
	transition: 100ms;

	&:hover {
		opacity: 0.4;
	}

	i {
		font-size: 1.3rem;
		opacity: 0.7;
		margin-left: 0.6rem;
		margin-bottom: -1px;
	}

	&:active {
		opacity: 0.1;
		transition: 600ms;
	}
}

.os-results-container {
	padding-top: 365px;
}

.os-result-section {
	border-bottom: 1px solid rgba(#fff, 0.1);
	padding-bottom: 1rem;
	margin-bottom: 1rem;
}

.os-section-header {
	margin-bottom: 1rem;
	font-size: 0.8rem;
	opacity: 0.4;
	margin-left: 0.6rem;
	font-weight: 600;
	display: flex;
	align-items: center;
}

.os-input-container {
	position: relative;
	margin-bottom: 40px;

	i {
		position: absolute;
		left: 0;
		top: 25%;
		color: $colour-primary;
		transform: translateY(20%);
		font-size: 2.5rem;
	}

	.loading-icon-container {
		width: auto;
		position: absolute;
		right: 5px;
		bottom: 20px;
		margin: 0;
	}
}

#os-input {
	width: 100%;
	font-size: 2.4rem;
	font-weight: 700;
	padding-left: 3rem;
	padding-bottom: 0.2rem;
	border: none;
	border-bottom: 2px solid rgba(#fff, 0.1);
	background-color: transparent;
	outline: none;
	color: $colour-text;
	transition: ease-in 200ms;
	border-radius: 0;
	height: 78px;

	&::placeholder {
		color: $colour-text;
		opacity: 0.1;
	}

	&:focus {
		border-bottom: 2px solid $colour-primary;
	}
}

.os-result-item {
	font-size: 1rem;
	font-weight: 500;
	cursor: pointer;
	padding: 0.6em 0.5rem;
	margin: auto;
	max-width: 1200px;
	border-radius: 10px;

	&.os-result-selected {
		background-color: rgba($colour-primary, 0.7);

		.os-result-tag {
			color: #fff;
			opacity: 0.6;
		}

	}

	&:hover {
		background-color: rgba($colour-secondary, 0.7);
	}
	
}

.os-result-tag {
	font-size: 0.6rem;
	color: $colour-primary;
	margin-left: 0.5rem;
	bottom: 0;
}

