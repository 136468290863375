@import "@threeskye/core-components/dist/styles/Variables.scss";

$token-colour: #3d4567;
$client-colour: $colour-primary;
$npe-colour: #30c9ac;
$account-colour: #7f64ea;

#pending-orders-list-body {
	.tr-pending-orders {
		// display: grid;
		grid-template-columns: 1fr 10% 25% 7% minmax(120px, 10%) repeat(4,8%);
		min-width: 970px;
		align-items: center;

		.ca-token-outer {
			display: inline-flex;
			overflow: hidden;
			background-color: $token-colour;
			cursor: pointer;
		}

		&.ca-token-small {
			border-radius: 4px;
			height: 24px;
			transition: 200ms;
			cursor: pointer;
		}
	}

	.ca-token-account {
		.ca-token-content {
			.ca-token-value {
				span {
					max-width: 345px;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
				// &.td-account-name {
				// 	overflow: hidden;
				// 	white-space: nowrap;
				// 	text-overflow: ellipsis;
				// }
			}
		}
	}
}

#bookmarks-global-search {
	.ca-token-content {
		display: grid;
		// grid-template-columns: 57% repeat(3, 14.3%);
		// grid-template-columns: minmax(230px, 34%) 7% 18% 11% 11% 11% 11% 8%;
		grid-template-columns: 70% 11% 11% 8%;
		padding: 0 1rem;
		width: 100%;
		min-width: 1034px;

		.ca-token-value {
			&.right-align {
				text-align: right;
			}

			&:first-of-type {
				font-weight: 600;
			}

			.ca-token-value-sub {
				display: none;
			}
			&.alignAccount {
				.ca-token-value-label {
					text-align: left;
				}
				span {
					margin-left: 44px;
				}
			}
		}

		.ca-token-icons {		
			justify-content: flex-start;

			transform: translateY(2px);

			.ca-token-icon-medium {
				display: none;
			}

			.ca-token-icon {
				margin-right: 8px;

				i {
					font-size: 18px;
				}
			}
		}
	}
}

#dashboard-reviews-list-body {
	.tr-reviews {
		display: grid;
		grid-template-columns: 1.5fr 0.75fr 1fr 1.25fr 0.75fr 0.75fr 0.25fr;
		align-items: center;

		&.completed {
			grid-template-columns: 1.5fr 0.75fr 1fr 1.25fr 0.75fr 0.25fr;

		}
	}
}

.ca-token-outer {
	.ca-token-icons {		
		justify-content: flex-start;

		transform: translateY(2px);

		.ca-token-icon-medium {
			display: none;
		}

		.ca-token-icon {
			margin-right: 8px;

			i {
				font-size: 18px;
			}
		}
	}
}

.ca-token-outer	{
	display: inline-flex;
	overflow: hidden;
	background-color: $token-colour;
	cursor: pointer;

	.ca-token-sub-value {
		padding-left: 4px;
		margin-left: 4px;
		border-left: 1.5px solid rgba(#9898CB, 0.3);
	}

	.status-tag {
		transform: translateY(2px);
	}

	// Token margin prop
	&.margin-small {
		margin: 0 0.5rem 0.5rem 0;
	}

	&.margin-medium {
		margin: 0 1rem 1rem 0;
	}

	&.margin-large {
		margin: 0 1.5rem 1.5rem 0;
	}

	// Token Type styles
	// Client
	&.ca-token-client {
		.ca-type-indicator {
			background-color: $client-colour;

			&::before,
			&::after {
				background-color: $client-colour;
			}
		}
	}

	// NPE(non personal entity)
	&.ca-token-npe {
		.ca-type-indicator {
			background-color: $npe-colour;

			&::before,
			&::after {
				background-color: $npe-colour;
			}
		}
	}

	// Account
	&.ca-token-account {
		.ca-type-indicator {
			background-color: $account-colour;

			&::before,
			&::after {
				background-color: $account-colour;
			}
		}
	}

	.indicator-red {
		width: 3px;
		height: 15px;
		background-color: #E35953;
		margin-right: 3px;
		border-radius: 1.5px;
	}

	.indicator-gold {
		width: 3px;
		height: 15px;
		background-color: $colour-warning;
		margin-right: 3px;
		border-radius: 1.5px;
	}

	.indicator-green {
		width: 3px;
		height: 15px;
		background-color: $colour-success;
		margin-right: 3px;
		border-radius: 1.5px;
	}

	.indicator-null {
		display: none;
	}

	.ca-token-next-review-container {
		display: flex;
		flex-direction: row;
		transform: translateX(-6px);
		min-width: 120px;
	}

	.no-date {
		transform: translateX(0) !important;
	}

	.ca-token-value-label {
		display: none;
	}

	.ca-token-icons {
		display: flex;
		z-index: 1;

		.ca-token-icon {
			display: inline-flex;

			i {
				font-size: 20px;

				&.delete {
					color: $colour-warning;
				}

				&.primary-badge {
					color: rgb(250, 195, 94);
					opacity: 1;
				}
			}

			&:last-of-type {
				margin-right: 0 !important;
			}
		}
	}

	// Small Token Styles
	&.ca-token-small {
		border-radius: 4px;
		height: 24px;
		transition: 200ms;
		cursor: pointer;
		// width: 100%;
		overflow: hidden;
		gap: 4px;
		padding-right: 4px;

		&:hover {
			background-color: lighten($token-colour, 8%);
		}

		.ca-type-indicator {
			width: 24px;
			flex-shrink: 0;

			i {
				font-size: 16px;
				margin: 0 !important;
			}
		}

		.ca-token-content {
			font-size: 13.8px;
			padding: 0;
			width: calc(100% - 27px);

			.ca-token-remove {
				margin-left: 1rem;

				i {
					font-size: 16px;
				}
			}

			.ca-token-value {
				display: flex;
				align-items: center;
				width: 100%;

				.ca-token-value-sub {
					font-size: 12px;
					opacity: 0.7;
					margin-left: 5px;
				}
			}

			.ca-token-icons {
				margin-left: 8px;
				margin-right: 4px;
				transform: none;
				i {
					font-size: 1rem;
				}
			}
		}

		.ca-name-span {
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
			padding-right: 3px
		}

		.popup-outer {
			width: 100%;
		}
	}

	// Medium Token Styles
	&.ca-token-medium {
		border-radius: 4px;
		height: 40px;
		margin-right: 8px;
		position: relative;
		overflow: unset;
		cursor: pointer;
		transition: 200ms ease;

		.ca-type-indicator {
			min-width: 24px;
			border-top-left-radius: 4px;
			border-bottom-left-radius: 4px;

			i {
				font-size: 16px;
			}
		}

		.ca-token-content {
			font-size: 13.8px;
			padding: 0 8px;
			justify-content: space-between;
			width: calc(100% - 30px);

			.ca-token-value {
				display: inline-block;
				align-items: center;
				width: auto;
				max-width: 80%;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				font-size: 16px;

				.ca-token-value-sub {
					display: none;
				}
			}

			.ca-token-icons {
				margin-left: 8px;

				.ca-token-icon {
					margin-right: 8px;
				}
			}
		}

		&:hover {
			background-color: lighten($token-colour, 8%);
		}
	}

	// Large Token Styles
	&.ca-token-large {
		border-radius: 4px;
		height: 56px;
		width: 100%;
		overflow: unset;
		margin-bottom: 8px;
		transition: 100ms ease;
		min-width: 1050px;

		&:hover {
			cursor: pointer;
			background-color: lighten($token-colour, 10%);

			.ca-type-indicator {
				width: 37px;

				// Stretchy Hover Effect
				// &::after, &::before {
				// 	width: 37px;
				// }
				// &:after {
				// 	transform: skew(9deg);
				// 	transform-origin: 0% 100%;
				// }
				// &:before {
				// 	transform: skew(-9deg);
				// 	transform-origin: 0% 0%;
				// }
			}
		}

		.ca-type-indicator {
			transition: 100ms ease;
			border-top-left-radius: 4px;
			border-bottom-left-radius: 4px;
			width: 32px;
			overflow: hidden;
			position: relative;
			flex-shrink: 0;

			// Stretchy Hover Effect
			// &::after, &::before {
			// 	content: "";
			// 	width: 28px;
			// 	height: 50%;
			// 	position: absolute;
			// 	left: 0;
			// 	transition: 300ms ease;
			// 	z-index: 0;
			// }
			// &::after {
			// 	top: 0;
			// }
			// &::before {
			// 	bottom: 0;
			// }

			i {
				z-index: 1;
				font-size: 20px;
			}
		}

		.ca-token-value {
			padding: 0 16px;

			&:first-of-type {
				padding-left: 0;
			}

			&:last-of-type {
				padding-right: 0;
			}

			.ca-token-value-label {
				display: block;
				font-size: 12px;
				opacity: 0.6;
				margin-bottom: 4px;
				font-weight: 400;
			}

			.popup-outer {
				width: 100%;

				.popup-outer-inner {
					display: block;
					width: 100%;
					
					span {
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
						display: inline-block;
						width: 100%;
						padding-right: 5px;
					}
				}
			}
		}

		.ca-token-content {
			display: grid;
			grid-template-columns: 57% repeat(3, 14.3%);
			// grid-template-columns: 70.2% 12.5% 8.6% 8.6%;
			padding: 0 1rem;
			width: 100%;

			.ca-token-value {
				&.right-align {
					text-align: right;
				}

				&:first-of-type {
					font-weight: 600;
				}

				.ca-token-value-sub {
					display: none;
				}
			}

			.ca-token-icons {
				justify-content: flex-start;

				transform: translateY(2px);

				.ca-token-icon-medium {
					display: none;
				}

				.ca-token-icon {
					margin-right: 8px;

					i {
						font-size: 18px;
					}
				}
			}

			&.adviser-group-token {
				grid-template-columns: 1fr auto 40px !important;
				cursor: default;

				i {
					cursor: pointer;
				}
			}
		}

		&.ca-token-npe {
			.ca-token-icons {
				display: none;
			}

			.ca-token-content {
				grid-template-columns: 57% repeat(3, 14.3%);
				// grid-template-columns: 70.2% 12.5% 8.6% 8.6%;
			}
		}

		&.ca-token-account {
			.ca-token-content {
				grid-template-columns: minmax(200px,500px) 82px 170px repeat(2,110px) 124px repeat(2,110px)
			}
		}
	}
}

// Generic styles
.ca-type-indicator {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.ca-token-content {
	display: flex;
	align-items: center;
	// width: 100%;

	.ca-token-remove {
		display: inline-flex;

		i {
			color: #fff;
			opacity: 0.5;
			cursor: pointer;

			&:hover {
				opacity: 1;
			}
		}
	}
}

.ca-token-custom-icons {
	margin-left: 8px;
	display: flex;
	align-items: center;
	gap: 8px;
}