@import "@threeskye/core-components/dist/styles/Variables.scss";
@import "./3skye.scss";
@import "./crm-date-field.scss";

body {
	background-color: $colour-background;
}

hr {
	border: none;
	display: block;
	width: 100%;
	height: 1px;
	background-color: #6f7ba7;
	margin: 1.3em auto;
	opacity: 0.6;
}

ul {
	list-style-type: none;
}

.page-container {
	width: 90%;
	margin: 0 auto;
}

.align-center {
	display: flex;
	align-items: center;
}

// Icon Colours
.coin {
	color: #f8d434;
}

.cake {
	color: #1fddff;
}

.alert, .warning {
	color: #e2d40a;
}

.document, .insert_drive_file {
	color: #0fa7ff;
}

.price-tag {
	color: #68e4aa;
}

.order, .monetization_on {
	color: #2ac450;
}

.upward-arrow {
	color: #37e26c;
}

.downward-arrow {
	color: #f44d46;
}

.notes-icon {
	color: #e2d177;
}

.unfinished-icon {
	color: #fc7d34;
}

.attachment {
	color: $secondaryColour-3;
}

.bookmark {
	color: $colour-primary;
}

.phone-call, .phone {
	color: $secondaryColour-1;
}

.date-range, .date_range {
	color: #f2cb62;
}

.email {
	color: #5985fc;
}

.btn-danger {
	background-color: $secondaryColour-2;
	color: #fff;
}

.light-theme {
	.main-header-container {
		box-shadow: 0 3px 6px rgba(#000, 0.16);
		background-color: $light-colour-background;
	}
}

// Global layout styles. ** DON'T FORGET TO MOVE **

// #master-grid {
// 	grid-column-gap: 0;
// 	grid-template-rows: 1fr;
// 	padding-bottom: 100px;
// 	min-width: 800px;
// }

.justified-header {
	display: flex;
	flex-wrap: wrap;
}

.main-content-grid {
	margin-top: 170px;
	width: 100%;
}

.page-header {
	width: 90%;
	margin: 0 auto;
}

.page-title {
	font-size: 1.2rem;
	font-weight: 500;
}

.flex-center {
	display: flex;
	align-items: center;
}

a {
	&:hover {
		color: $colour-primary;
	}
}

// @media (min-width: $breakpoint-md) {
	// #master-grid {
	// 	grid-template-columns: 80px 2em repeat(12, 1fr) 2rem;
	// }

	.main-content-grid {
		width: auto;
		margin-top: 0;
		grid-row: 1;
		grid-column: 2/16;
		display: grid;
		grid-template-columns: repeat(12, 1fr);
		grid-template-rows: 100px auto;

		&.full-width-content {
			.main-content-view-container {
				width: 100% !important;
				margin-left: 0;
				margin-right: 0;
			}
	
			.main-content-view {
				// height: 100vh;
				padding-left: 340px;
				width: 100%;
				overflow: hidden;
				top: 0;
				left: 0;
				position: absolute;
				padding-top: 196px;
			}
		}
	}

	.main-header-container {
		z-index: $layer-7;
		width: 100%;
		margin-left: -100px;
		position: fixed;
		box-shadow: 0 3px 6px rgba(#000, 0.16);
		background-color: $colour-background;
	}

	.main-header-content,
	.main-content-view-container {
		width: 90%;
		margin: auto;
		margin-left: 24px;
	}

	.main-content-view-container {
		grid-column: span 12;
		grid-row: 2;
		margin-top: 45px;
	}

	.mobile-only-hr {
		display: none;
	}

	.page-header {
		width: 100%;
	}

	.justified-header {
		justify-content: space-between;
		align-items: center;
	}

	.page-container {
		width: 100%;
	}


@media (min-width: 1000px) {
	.main-content-view-container {
		margin-top: 45px;
	}

	.main-header-container {
		width: 100% !important;
		margin-left: -80px !important;
		padding-left: 80px !important;
	}
}

@media screen and (min-width: 1400px){
	.main-header-content,
	.main-content-view-container {
		width: 90%;
		margin-left: 24px;
		max-width: 1500px;
		min-width: 800px;
	}
}

.btn {
	padding: 0.4em 1.5rem;
	font-size: 14px;
	transition-duration: 200ms;
	cursor: pointer;
}

.btn-w-icon {
	display: inline-flex;
	align-items: center;
	justify-content: center;

	i {
		font-size: 14px;
	}
}

.btn-icon-right {
	i {
		margin-left: 0.6rem;
	}
}

.btn-icon-left {
	i {
		margin-right: 0.6rem;
	}
}

.grid-small-header {
	grid-template-rows: 155px auto !important;
}

.grid-large-header {
	grid-template-rows: 176px auto !important;
}

.btn-primary {
	border: 1px solid $colour-primary;

	i {
		color: $colour-primary;
	}

	&:hover {
		background-color: $colour-primary;

		i {
			color: #fff !important;
		}
	}
}

.btn-primary-filled {
	border: 1px solid $colour-primary;
	background-color: $colour-primary;
	color: #fff;

	i {
		color: #fff;
	}

	&:hover {
		background-color: lighten($colour-primary, 8%);
	}
}

// TEMP STYLES
// Temporary styles to be removed after core-components release.
button,
.button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
	border-style: solid;
}

// Remove the white background from the selects. Also takes care of that annoying Firefox focus border >:) -CM
select {
	&:focus {
		background-color: lighten($colour-form-input, 15%) !important;
		color: #fff !important;
		outline: none !important;
	}

	&:-moz-focusring {
		// Change the text-shadow colour to change the colour of the select value and options.
		color: transparent !important;
		outline: none !important;
		text-shadow: 0 0 0 $colour-form-text !important;
	}

	option {
		padding: 7px !important;
		font-size: 1.1rem !important;	
	}
}

// Temp mobile specific styles
@media screen and (max-width: $breakpoint-md) {
	body {
		// overflow: hidden;
	}

	#master-grid {
		min-width: auto;

		.main-content-grid {
			display: flex;
			flex-wrap: wrap;
		}

		.main-content-view-container {
			display: none;
		}

		#side-nav {
			display: none;
		}

		#topbar-container {
			height: 70px;

			
			#navigation-buttons {
				display: none;
			}
		}
		
		.overlay {
			z-index: 100;
			top: 70px;
			height: calc(100vh - 70px);
			background-color: transparent;

			.sidebar-container {
				width: 100%;
				height: 100%;

				.sidebar-list-container {
					height: 92%;
					padding-top: 0;
				}

				.sidebar-footer {
					height: auto;
					padding: 1rem;
					box-shadow: 0 -6px 3px 0 rgba(#0000, 0.2);
					display: none;

					button {
						height: 48px;
						&:nth-of-type(2) {
							display: none;
						}
					}
				}
			}
		}
	}
}
