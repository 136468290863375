@import "@threeskye/core-components/dist/styles/Variables.scss";

.input-validation-warning-message {
	display: flex;
	align-items: center;
	color: #fff;
}

.input-validation-warning-icon {
	margin-right: 5px;
	display: flex;
	align-items: center;
	transform: translateY(-1px);

	i {
		font-size: 1rem;
	}
	
	.validation-error {
		color: $colour-warning;
	}
}

.input-validation-text-invalid {
	border: 1px solid $colour-warning !important;
	background-color: rgba($colour-warning, 0.25) !important;

	&:focus {
		color: #fff !important;

		&::placeholder {
			color: #fff !important;
		}
	}
}

.input-validation-prefix {
	position: relative;
	display: flex;
	align-items: center;

	input {
		padding-left: 10px;
		width: calc(100% - 54px);
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		border-width: 1.5px;
		border-left: 2px solid #4e5b85;
	}

	.input-validation-text {
		border-top-left-radius: 0 !important;
		border-bottom-left-radius: 0 !important;
		border: none;
		height: 36px !important;
		border-left: 2px solid #3B425D !important;
	}

	p {		
		color: $colour-secondary;
		opacity: 0.6;
		position: absolute;
		left: 10px;
		cursor: pointer;
	}

	i {
		color: $colour-primary;
		position: absolute;
		left: 10px;
		cursor: pointer;
		&:hover {
			opacity: 0.6;
		}
	}

	.input-validation-text-invalid {
		border-top-left-radius: 0 !important;
		border-bottom-left-radius: 0 !important;
		outline: 1px solid $colour-warning !important;
		background-color: rgba($colour-warning, 0.25);
	}

	&.extension {
		input {
			&:first-of-type {
				border-radius: 0;
				margin: 0;
			}
		}

		.extension-input {
			width: 100px;	
			border: none;
			height: 32px !important;
			border-left: 2px solid #4e5b85 !important;
		}
	}
}

.reset {
	all: initial;

	p {
		line-height: initial;
	}
}

// Prefix styling
.input-validation-prefix {
	.prefix-input {
		width: 54px;
		margin: 0;
		padding: 1.5px 0;
		
		.token-field__control {
			margin-top: 0;
			border-bottom-right-radius: 0;
			border-top-right-radius: 0;
		}

		&.input-invalid {
			.token-field__control {
				outline: 1px solid $colour-warning !important;
				border-right: none !important;
				background-color: rgba($colour-warning, 0.25) !important;
				
				input {
					color: #fff;
				}

				.token-field__placeholder {
					color: #fff;
				}

				.token-field__input {
					color: #fff;
				}
			}
		}
	}
	
	.token-field__control {
		min-height: 32px;

		.prefix-suggestion {
			.prefix-label {
				display: none;
			}
		}
	}

	.token-field {
		.token-field__menu {
			width: 180px;
		}
	}
}