$colour-background: #1a2038;
$colour-primary: #0d8efd;
$colour-secondary: #30395a;
$secondaryColour-1: #00d1a9;
$secondaryColour-2: #ed443d;
$secondaryColour-3: #5985fc;
$darkText: #1a2038;
$colour-text: #fff;
$primaryFont: "proxima-nova", sans-serif !important;

//*****************************
// LIGHT THEME
//*****************************

// GENERAL
$light-colour-background: rgb(255, 255, 255);
$light-colour-primary: #0d8efd;
$light-colour-secondary: #dcdcdc;


// BUTTONS
$light-colour-button-bg: $light-colour-primary;
$light-colour-button-text: #fff;

// STATES
$colour-success: #30c394;
$colour-warning: #f7a43d;
$colour-danger: #E35953;

// ACTIONS
$light-colour-hover-background: #e9e9e9;

// TYPOGRAPHY
$light-colour-text: #1a2038;
$light-colour-link-primary: $light-colour-primary;
$light-colour-link-alt: rgba($light-colour-text, 0.3);
$light-colour-link-primary-highlight: $light-colour-primary;
$light-colour-link-alt-highlight: $light-colour-primary;

// FORMS
$light-colour-form-text: #30395a;
$light-colour-form-input: #f7f7f7;
$light-colour-form-focus: #30395a;
$light-colour-form-focus-text: #fff;

// SIDEBAR
$light-colour-sidebar: #1a2038;
$light-colour-sidebar-events: #30395A;

// MISC
$light-colour-overlay: #101423;
$light-colour-container: #fff;
$light-colour-portfolio-bar: #475073;

.form {
	.form-header-section {
		margin-bottom: 20px;

		.form-header {
			margin: 0 !important;
			margin-bottom: 5px !important;
		}

		.form-header-sub {
			opacity: 0.6;
		}
	}
}