@import "@threeskye/core-components/dist/styles/Variables.scss";

.file-upload-outer-container {
	position: fixed;
	width: 350px;
	z-index: 2001;
	bottom: 20px;
	right: 30px;
	background-color: lighten($colour-background, 4%);
	border-radius: 8px;
	font-size: 0.9rem;
	box-shadow: 0 3px 6px rgba(#000, 0.4);
	overflow: hidden;
}

.file-upload-inner-container {
	padding: 1em 1.4rem;
}

.file-upload-header {
	background-color: darken($colour-background, 1%);
	padding: 1em 1.4rem;
	width: 100%;
	font-size: 0.9rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.file-upload-progress {
	margin-top: 2rem;

	&:first-child {
		margin-top: 0;
	}
}

.file-upload-progress-outer {
	width: 100%;
	height: 6px;
	display: block;
	margin-top: 0.4rem;
	border-radius: 100px;
	background-color: rgba(#fff, 0.1);
}

.file-upload-details {
	width: 100%;
	display: flex;
}

.file-upload-file-name {
	width: 60%;
    white-space: nowrap;
    overflow: hidden;
	text-overflow: ellipsis;
	margin-right: 0.2rem;
}

.file-upload-progress-bar {
	height: 100%;
	border-radius: 100px;
	background-color: $colour-primary;
}

.file-upload-percentage {
	opacity: 0.6;
}

.file-upload-cancel {
	margin-left: auto;
	cursor: pointer;
	transition: 300ms;

	i {
		font-size: 1rem;
		color: #fff;
	}

	&:hover {
		i {
			color: $colour-primary;
		}
	}
}

.invalid-file-type {

	.file-upload-file-name {
		width: 40%;
	}

	.file-upload-percentage {
		opacity: 1;
		color: $colour-warning;
	}
}

.invalid-file-message {
	display: flex;
	align-items: center;
	font-weight: 500;

	i {
		font-size: 1.1rem;
	}
}
