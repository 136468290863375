@import "@threeskye/core-components/dist/styles/Variables.scss";

.crm-error-message {
    display: inherit !important;
    width: 100%;
    outline: none;
    margin: 0px;

    &.is-invalid {
        .input-with-validation, input[type="text"], input[type="number"], input[type="password"], .date-field-outer, .token-field__control, .dropdown-value-wrapper, .time-field-outer, .tta-editor {
            outline: 1px solid $colour-warning;
            background-color: rgba($colour-warning, 0.25);
            
            &.token-field__control--is-focused {
                background-color: rgba($colour-warning, 0.25);

                .token-field__placeholder {
                    color: #fff !important;
                    opacity: 0.4;
                }
            }
        }

        .token-field__control {
            outline: 1px solid $colour-warning !important;
        }

        .input-with-validation, input[type="text"], input[type="number"], input[type="password"], .token-field__input, .date-field-field, .calendar-trigger, .dropdown-value-wrapper,  .token-field__placeholder, .time-field-outer, .tta-editor {
            color: white;

            &::placeholder {
                color: white;
            }
        }

        // Date/Time field styles
        .date-field-outer, .time-field-outer {
            color: #fff;

            i {
                color: $colour-warning;
            }

            .date-field-field {
                background-color: transparent;
                color: #fff;
    
                &::placeholder {
                    color: #fff;
                }
            }

            &:focus-within {
                color: #fff;

                i {
                    color: $colour-warning;
                }

                .date-field-field, input {
                    background-color: transparent;
                    color: #fff;
        
                    &::placeholder {
                        color: #fff;
                    }
                }
            }
        }

        .tta-editor {
            margin-bottom: 6px;
        }

        .dropdown-value-wrapper {
            margin-bottom: 0.4rem;
        }

        .checkbox-field {
            input[type="radio"] {
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                padding: 12px 12px 0px 0px;
                margin: 0px 10px -0.25em 0px;
                border-radius: 0.5rem;
              
                border: 1.5px solid rgba($colour-warning, 1);
                background-color: white;
            }
        }
        
        .input-validation-warning-message {
            display: none;
        }
    }

}

.input-validation-warning-message {
    animation: fadeUp 500ms;
    margin-top: 0.4rem;
    &.parent-error {
        padding: 0px 8px;
    }
}

@keyframes fadeUp {
    0% {
        opacity: 0;
        transform: translateY(30px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
