@import "@threeskye/core-components/dist/styles/Variables.scss";

.adviser-token-wrapper {
	display: flex;
	align-items: center;
	transition: 200ms ease;

	.token-name-value {
		background-color: $colour-primary;
		border-top-right-radius: 4px;
		border-bottom-right-radius: 4px;
		height: 24px;
		display: flex;
		align-items: center;
		padding: 0 4px;
		font-size: 14px;

		&.small {
			height: 20px;
			font-size: 12px;
		}
	}

	.token-logo {
		width: 24px;
		min-width: 24px;
		height: 24px;
		border-top-left-radius: 4px;
		border-bottom-left-radius: 4px;
		overflow: hidden;
		background-color: #fff;
		display: flex;
		justify-content: center;
		align-items: center;

		img {
			height: 57%;
			width: 80%;
		}

		&.small {
			height: 20px;
			width: 20px !important;
		}
	}

	&.ellipse-token {
		.token-name-value {
			span {
				max-width: 125px;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;

				@media screen and (max-width: 1250px) {
					max-width: 100px;
				}
			}
		}
	}
}

#requestor {
	.token-name-value {
		span {
			max-width: 125px;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	}
}



.adviser-info {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 2px;

	.adviser-info-item {
		padding: 10px 6px;
		border-radius: 4px;
		display: flex;
		align-items: center;
		gap: 10px;
		cursor: pointer;
		width: 100%;

		&:hover {
			background-color: rgba(#fff, 0.05);
		}

		i {
			font-size: 16px;
		}

		&.info-phone {
			i {
				color: $colour-success;
			}
		}

		&.info-email {
			i {
				color: #5985fc;
			}
		}

		&.info-location {
			i {
				color: $colour-primary;
			}
		}
	}
}