@import "../../layout/LayoutVariables.scss";
@import "@threeskye/core-components/dist/styles/Variables.scss";


// MIXINS
@mixin VerticalScroll {
	overflow-y: auto;

	&.email-template-mobile-view {
		overflow-y: visible;
	}
	&::-webkit-scrollbar {
		display: none;
	}

	-ms-overflow-style: none;
	scrollbar-width: none;
}

#side-nav-extension {
	z-index: 1;
	@include VerticalScroll();
	width: 100%;
	background-color: #1A2038;
	padding: $content-top-margin $content-right-margin;
	// box-shadow: -3px 1px 9px rgba(#000, 0.2);

	// Calendar styles

	.date-time-input-container {
		.react-calendar {
			width: 320px;
		}
	}
	
	.date-range-inputs {
		.react-calendar {
			width: 320px;
		}

		.date-field:nth-of-type(2) {
			.date-field-outer .calendar-wrapper-showing {
				right: -1px;
				left: auto;
			}
		}	
	}
	
	&.extension-left {
		padding-right: 0;
		grid-area: side-nav-left-extension;

		@media screen and (max-width: $breakpoint-mobile) {
			display: none;
		}

	}
	
	&.extension-right {
		padding-left: 1.5rem;
		border-left: 1px solid #30395a;
		grid-area: side-nav-right-extension;
		z-index: 86;

		@media screen and (max-width: $breakpoint-mobile) {
			padding: 1rem;
			height: auto;
			padding-top: 72px;
			padding-bottom: 80px;
		}
	}

	.extension-header {
		font-size: 16px;
		font-weight: 700;
		margin-bottom: 24px;
	}

	&.extension-fixed {
		position: fixed;
    	right: 56px;
    	top: 64px;
    	height: calc(100vh - 64px);
	}

	.extension-icon {
		color: $colour-primary;
		font-size: 14px;
		opacity: 1;
	}
}

.side-nav-section {
	margin-bottom: 24px;
}

.side-nav-section-header {
	p {
		color: #fff;
		opacity: 0.5;
		font-size: 12px;
		font-weight: 600;
	}
	
	margin-bottom: 16px;
	display: flex;
	align-items: center;
	gap: 4px;
}

.side-extension-hidden {
	display: none;
	opacity: 0;
	pointer-events: none;
}