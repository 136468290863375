@import "@threeskye/core-components/dist/styles/Variables.scss";

// VARIABLES
$sidebar-width-mobile: 250px;
$sidebar-width: 320px;

// STYLES
.sidebar-outer {
	width: 100%;
	height: 100vh;
	top: 0;
	left: 0;
	position: fixed;
	z-index: $layer-9;
	visibility: hidden;
}

.sidebar-container {
	background-color: $colour-sidebar;
	width: $sidebar-width-mobile;
	height: 100vh;
	top: 0;
	padding: 0 1rem;
	right: -$sidebar-width-mobile;
	position: absolute;
	visibility: hidden;
	opacity: 0;
	transition: 200ms ease-in-out;
	
	&.left {
		left: -$sidebar-width-mobile;

		&.sidebar-fadein {
			left: 0;
		}
	}

	hr {
		margin: 0.2em auto;
		width: 85%;
		border-top: 0.5px solid #fff;
		opacity: 0.1;
	}
}

.sidebar-outer-fadein {
	display: block;
}

.sidebar-fadein {
	opacity: 1;
    right: 0;
    visibility: visible;
}

.sidebar-button-container {
	margin-top: 1rem;
	display: flex;
	justify-content: space-between;
}

@media (min-width: $breakpoint-md) {
	.sidebar-container {
		width: $sidebar-width;
	}
}

.sidebar-header {
	height: 8%;
	border-bottom: 1px solid rgba(#fff, 0.2);
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: row-reverse;
	padding-top: 10px;

	h5 {
		margin-bottom: 0;
	}

	.sidebar-close {
		cursor: pointer;
		color: $colour-primary;
		transition: 200ms;
	
		&:hover {
			opacity: 0.5;
		}
	}
}

.sidebar-prompt-container {
	margin-top: 2rem;
}

// TOGGLE BUTTONS
.sidebar-toggle-container {
	width: 100%;
	padding: 1em 1.5rem;
}

.sidebar-toggles {
	border-radius: 5px;
	overflow: hidden;
	display: flex;
	margin-top: 0.3rem;
	margin-bottom: 1rem;

	span {
		text-align: center;
		width: 50%;
		background-color: $colour-secondary;
//		text-transform: uppercase;
		padding: 0.75em 0;
		font-size: 0.7rem;
		letter-spacing: 1px;
		font-weight: 500;
		cursor: pointer;
		opacity: 0.5;
		
		&:hover {
			opacity: 0.7;
		}
	}

	.activeToggle {
		opacity: 1;
		background-color: $colour-primary;
	}
	
}

// SIDEBAR LIST
.sidebar-list {
	position: relative;
	width: 100%;
	height: 100%;
	left: 0;
	overflow: auto;
	padding-bottom: 100px;
	padding-top: 10px;

	li {
		margin: 0;
		margin-bottom: 12px;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: space-between;
		
		span {
			width: 80%;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		i {
			font-size: 1rem;
			opacity: 0.5;

			&.minimised-event-delete {
				font-size: 1.5rem;
			}
			
			&:hover {
				opacity: 1;
			}
		}
	}
}
	
.sidebar-list-container {
	height: 77%;
    overflow: hidden;
	position: relative;
	padding-top: 18px;
}

.sidebar-section-header {
	// Hiding this for now...
	display: none !important;
	font-size: 1.3rem;
	margin-bottom: 1rem;

	h4 {
		text-align: center; 
		font-size: 0.75rem; 
		padding-top: 5px; 
		font-weight: 400; 
		padding-bottom: 10px; 
		padding-top: 10px; 
		margin-top: 1rem; 
		letter-spacing: 3px;
		width: 100%;
		background-color: $colour-primary;
		border-radius: 4px;
	}
}

// SIDEBAR FOOTER

.sidebar-footer {
	position: absolute;
    bottom: 0;
    padding: 1rem;
	width: 100%;
	height: 15%;
	left: 0;
	background-color: $colour-sidebar;
	box-shadow: 0 -2px 3px rgba(#000, 0.1);
}


@media screen and (max-width: $breakpoint-md) {
	.sidebar-header {
		height: auto;
		padding-top: 8px;
		padding-bottom: 16px;
		border: none;
	}

	.sidebar-list {
		padding-top: 0;
	}
}